var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container"},[_c('v-row',{},[_c('v-col',{staticClass:"top-panel",attrs:{"cols":"12"}},[_c('div',{staticClass:"add-btn",on:{"click":_vm.createItem}},[_c('img',{attrs:{"src":require("../assets/img/plusAdd.svg"),"alt":"pluss"}}),_c('p',{staticClass:"add-text"},[_vm._v("Добавить")])]),_c('div',{staticClass:"vertical-line"})])],1),_c('div',{attrs:{"id":"manufacturer"}},[_c('v-container',{staticClass:"secondary mt-5"},[_c('v-row',[_c('v-col',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.filteredStaffs,"hide-default-footer":""},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',{staticClass:"page-title"},[_vm._v("Персонал ")]),_c('v-spacer'),_c('v-spacer'),_c('v-dialog',{attrs:{"max-width":"537px"},model:{value:(_vm.dialogCreate),callback:function ($$v) {_vm.dialogCreate=$$v},expression:"dialogCreate"}},[_c('v-card',[_c('v-card-title',{staticClass:"modal-title"},[_vm._v(" "+_vm._s(_vm.isEditing ? "Редактирование персонала" : "Добавление персонала")+" "),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":_vm.closeDialog}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-card-text',[_c('div',{staticClass:"py-0 px-0"},[_c('div',{staticClass:"col-content px-0 py-0 pb-0"},[_c('v-col',{staticClass:"col-paddings-r-l px-0 py-0",attrs:{"cols":"4"}},[_c('p',{staticClass:"col-title"},[_vm._v("Имя пользователя")])]),_c('v-col',{staticClass:"col-paddings-r-l px-0 py-0",attrs:{"cols":"8"}},[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.userFullName),expression:"userFullName"}],ref:"userFullNameTextarea",staticClass:"col-description textarea-pt",attrs:{"placeholder":_vm.isuserFullNameFocused
                                  ? ''
                                  : _vm.textareaPlaceholder},domProps:{"value":(_vm.userFullName)},on:{"focus":function($event){_vm.isuserFullNameFocused = true},"blur":function($event){_vm.isuserFullNameFocused = false},"input":function($event){if($event.target.composing){ return; }_vm.userFullName=$event.target.value}}})])],1)]),_c('div',{staticClass:"horizontal-line"}),_c('div',{staticClass:"py-0 px-0"},[_c('div',{staticClass:"col-content px-0 py-0 pb-0"},[_c('v-col',{staticClass:"col-paddings-r-l px-0 py-0",attrs:{"cols":"4"}},[_c('p',{staticClass:"col-title"},[_vm._v("Логин")])]),_c('v-col',{staticClass:"col-paddings-r-l px-0 py-0",attrs:{"cols":"8"}},[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.userName),expression:"userName"}],ref:"userNameTextarea",staticClass:"col-description textarea-pt",attrs:{"placeholder":_vm.isuserNameFocused ? '' : _vm.textareaPlaceholder},domProps:{"value":(_vm.userName)},on:{"focus":function($event){_vm.isuserNameFocused = true},"blur":function($event){_vm.isuserNameFocused = false},"input":function($event){if($event.target.composing){ return; }_vm.userName=$event.target.value}}})])],1)]),_c('div',{staticClass:"horizontal-line"}),_c('div',{staticClass:"py-0 px-0"},[_c('div',{staticClass:"col-content px-0 py-0 pb-0"},[_c('v-col',{staticClass:"col-paddings-r-l px-0 py-0",attrs:{"cols":"4"}},[_c('p',{staticClass:"col-title"},[_vm._v("Пароль")])]),_c('v-col',{staticClass:"col-paddings-r-l px-0 py-0",attrs:{"cols":"8"}},[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.userPassword),expression:"userPassword"}],ref:"userPasswordTextarea",staticClass:"col-description textarea-pt",attrs:{"disabled":_vm.isEditing == true,"placeholder":_vm.isuserPasswordFocused
                                  ? ''
                                  : _vm.textareaPlaceholder},domProps:{"value":(_vm.userPassword)},on:{"focus":function($event){_vm.isuserPasswordFocused = true},"blur":function($event){_vm.isuserPasswordFocused = false},"input":function($event){if($event.target.composing){ return; }_vm.userPassword=$event.target.value}}})])],1)]),_c('div',{staticClass:"horizontal-line"})]),_c('div',{class:_vm.isEditing === true
                          ? 'btn-container'
                          : 'btn-container-1 px-6'},[(_vm.isEditing)?_c('div',{class:_vm.currentState === 'Active'
                            ? 'save-btn-second'
                            : 'delete-btn',attrs:{"text":""},on:{"click":_vm.setUserState}},[_vm._v(" "+_vm._s(_vm.currentState === "Active" ? "Активировать" : "Деактивировать")+" ")]):_vm._e(),_c('div',{staticClass:"cancel-btn",attrs:{"text":""},on:{"click":_vm.closeCreate}},[_vm._v(" Отмена ")]),_c('div',{class:_vm.isEditing === true ? 'save-btn' : 'save-btn ml-7',attrs:{"text":""},on:{"click":_vm.handleButtonClick}},[_vm._v(" Сохранить ")])])],1)],1),_c('v-dialog',{attrs:{"max-width":"780px"},model:{value:(_vm.dialogDelete),callback:function ($$v) {_vm.dialogDelete=$$v},expression:"dialogDelete"}},[_c('v-card',[_c('v-card-title',{staticClass:"warning-title"},[_vm._v(_vm._s(_vm.currentState === "Active" ? "Вы точно хотите активировать данного сотрудника?" : "Вы точно хотите деактивировать данного сотрудника?")+" "),_c('v-btn',{attrs:{"icon":""},on:{"click":_vm.closeDelete}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-card-actions',{staticClass:"warning-margin"},[_c('div',{staticClass:"cancel-btn",attrs:{"text":""},on:{"click":_vm.closeDelete}},[_vm._v(" Отмена ")]),_c('div',{class:_vm.currentState === 'Active'
                            ? 'save-btn-paswd'
                            : 'del-btn',attrs:{"text":""},on:{"click":_vm.setUserState}},[_vm._v(" "+_vm._s(_vm.currentState === "Active" ? "Активировать" : "Деактивировать")+" ")])])],1)],1),_c('v-dialog',{attrs:{"max-width":"570px"},model:{value:(_vm.dialogChangePassword),callback:function ($$v) {_vm.dialogChangePassword=$$v},expression:"dialogChangePassword"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5"},[_vm._v("Cброс Пароля "),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":_vm.closeChangePassword}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-card-text',[_c('div',{staticClass:"py-0 px-0"},[_c('div',{staticClass:"col-content px-0 py-0 pb-0"},[_c('v-col',{staticClass:"col-paddings-r-l px-0 py-0",attrs:{"cols":"4"}},[_c('p',{staticClass:"col-title"},[_vm._v("Новый пароль")])]),_c('v-col',{staticClass:"col-paddings-r-l px-0 py-0",attrs:{"cols":"8"}},[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.newUserPassword),expression:"newUserPassword"}],staticClass:"col-description textarea-pt",attrs:{"placeholder":_vm.isNewUserPassword ? '' : _vm.textareaPlaceholder},domProps:{"value":(_vm.newUserPassword)},on:{"focus":function($event){_vm.isNewUserPassword = true},"blur":function($event){_vm.isNewUserPassword = false},"input":function($event){if($event.target.composing){ return; }_vm.newUserPassword=$event.target.value}}})])],1)]),_c('div',{staticClass:"horizontal-line"})]),_c('v-card-actions',[_c('v-spacer'),_c('div',{staticClass:"cancel-btn",attrs:{"text":""},on:{"click":_vm.closeChangePassword}},[_vm._v(" Отмена ")]),_c('div',{staticClass:"save-btn-paswd",attrs:{"text":""},on:{"click":function($event){return _vm.setNewPassword()}}},[_vm._v(" Сохранить ")]),_c('v-spacer')],1)],1)],1)],1),_c('v-snackbar',{staticClass:"btn-contaiener",attrs:{"timeout":3000},scopedSlots:_vm._u([{key:"action",fn:function(ref){
                            var attrs = ref.attrs;
return [_c('div',_vm._b({staticClass:"snackbar-btn",attrs:{"text":""},on:{"click":function($event){_vm.snackbar = false}}},'div',attrs,false),[_vm._v(" Закрыть ")])]}}]),model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.message)+" ")])]},proxy:true},{key:"item",fn:function(ref){
                            var item = ref.item;
                            var index = ref.index;
return [_c('tr',{staticStyle:{"cursor":"pointer"}},[_c('td',{on:{"click":function($event){return _vm.getUser(item)}}},[_vm._v(" "+_vm._s(item.fullName || " ")+" ")]),_c('td',{on:{"click":function($event){return _vm.getUser(item)}}},[_vm._v(" "+_vm._s(_vm._f("formatDate")(item.createdOn,"dd.MM.yyyy"))+" ")]),_c('td',{on:{"click":function($event){return _vm.getUser(item)}}},[_vm._v(" "+_vm._s(_vm._f("formatDate")(item.modifiedOn,"dd.MM.yyyy"))+" ")]),_c('td',{on:{"click":function($event){return _vm.getUser(item)}}},[_vm._v(" "+_vm._s(_vm.getStatusTranslation(item.stateCode))+" ")]),_c('td',{staticStyle:{"text-align":"right"}},[_c('v-icon',{attrs:{"medium":""},on:{"click":function($event){return _vm.toggleActionMenu(index)}}},[_vm._v("mdi-dots-vertical")]),(_vm.showActionMenu[index])?_c('div',{ref:"ActionMenu",staticClass:"ActionMenu"},[_c('div',{staticClass:"ActionMenu-item-1",on:{"click":function($event){return _vm.getUser(item)}}},[_vm._v(" Редактировать ")]),_c('div',{staticClass:"divider-modal"}),_c('div',{staticClass:"ActionMenu-item-2",on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v(" "+_vm._s(item.stateCode === "Active" ? "Деактивировать" : "Активировать")+" ")]),_c('div',{staticClass:"divider-modal"}),_c('div',{staticClass:"ActionMenu-item-2",on:{"click":function($event){return _vm.changePaswd(item)}}},[_vm._v(" Сброс Пароля ")])]):_vm._e()],1)])]}},{key:"no-data",fn:function(){return [_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary"}})]},proxy:true}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }